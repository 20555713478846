import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../../utils/axiosInstance';

// Fetch all tags
export const fetchTags = createAsyncThunk(
    'tags/fetchTags',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get('/tag');
            console.warn("response.data", response.data)
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
            toast.error(errorMessage);
            return rejectWithValue(error.response.data);
        }
    }
);

// Create a new tag
export const createTag = createAsyncThunk(
    'tags/createTag',
    async (tagData, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post('/tag', tagData);
            return response.data;
        } catch (error) {
            console.error(error)
            // const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
            // toast.error(error);
            return rejectWithValue(error.response.data);
        }
    }
);

// Update an existing tag
export const updateTag = createAsyncThunk(
    'tags/updateTag',
    async ({ id, updates }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.put(`/tag/${id}`, updates);
            return response.data;
        } catch (error) {
            // const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
            // toast.error(errorMessage);
            return rejectWithValue(error.response.data);
        }
    }
);

// Delete a tag
export const deleteTag = createAsyncThunk(
    'tags/deleteTag',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.delete(`/tag/${id}`);
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
            toast.error(errorMessage);
            return rejectWithValue(error.response.data);
        }
    }
);

// Delete a tag
export const addTagToCampaign = createAsyncThunk(
    'tags/deleteTag',
    async ({tagIds,campaignId}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(`/tag/add-to-campaign`,{campaignId, tagIds});
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
            toast.error(errorMessage);
            return rejectWithValue(error.response.data);
        }
    }
);
