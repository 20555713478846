// import { createSlice } from '@reduxjs/toolkit';
// import { fetchPrimaryUser, getCookie } from './setting';
// import { fetchBlockedList } from './blockList';
// import { fetchLabels } from './labels';
// import { fetchTags } from './tags';
// export { editPrimaryUser, deleteSessions, getCookie, fetchPrimaryUser, updatePassword } from './setting';
// export { delFromBlocklist, fetchBlockedList, uploadManualBlocklist, uploadSheet } from './blockList';
// export { createLabel, deleteLabel, updateLabel, fetchLabels } from './labels';
// export { changeRoles, deleteRole, getTeam, sendInvite } from './team';
// export { createTag, deleteTag, updateTag, fetchTags } from './tags';
// // export { fetchCampaignLeads } from './leads'

// const initialState = {
//   user: {
//     firstName: '',
//     lastName: '',
//     email: '',
//     password: '',
//     timeZone: '',
//     team: [],
//     blockedEntries: [],
//     tags: [],
//     labels: [],
//     cgptK: {}
//   },
//   status: 'idle',
//   sid: getCookie('pu_sid') || null,
//   error: null,
// };

// // Create the slice
// const userSlice = createSlice({
//   name: 'user',
//   initialState,
//   extraReducers: (builder) => {
//     builder
//       // Cases for fetching primary user
//       .addCase(fetchPrimaryUser.pending, (state) => {
//         state.status = 'loading';
//         state.error = null;
//       })
//       .addCase(fetchPrimaryUser.fulfilled, (state, action) => {
//         state.user = { ...state.user, ...action.payload };
//         state.status = 'succeeded';
//       })
//       .addCase(fetchPrimaryUser.rejected, (state, action) => {
//         state.status = 'failed';
//         state.error = action.payload;
//       })

//       // Cases for fetching blocked list
//       .addCase(fetchBlockedList.pending, (state) => {
//         state.status = 'loading';
//         state.error = null;
//       })
//       .addCase(fetchBlockedList.fulfilled, (state, action) => {
//         state.status = 'succeeded';
//         state.user.blockedEntries = action.payload.blockedEntries;
//       })
//       .addCase(fetchBlockedList.rejected, (state, action) => {
//         state.status = 'failed';
//         state.error = action.payload;
//       })
//       // Cases for fetching labels
//       .addCase(fetchLabels.pending, (state) => {
//         state.status = 'loading';
//         state.error = null;
//       })
//       .addCase(fetchLabels.fulfilled, (state, action) => {
//         state.status = 'succeeded';
//         state.user.labelNames = [
//           'Interested',
//           'Not Interested',
//           'Out of Office',
//           'Wrong Person',
//           'Not Contacted Yet',
//           ...action.payload.labels?.map(i => i.name)];
//         state.user.labels = [...action.payload.labels];
//       })
//       .addCase(fetchLabels.rejected, (state, action) => {
//         state.status = 'failed';
//         state.error = action.payload;
//       })

//       // Cases for fetching tags
//       .addCase(fetchTags.pending, (state) => {
//         state.status = 'loading';
//         state.error = null;
//       })
//       // .addCase(fetchTags.fulfilled, (state, action) => {
//       //   console.warn("icoming tags",action.payload.tags)
//       //   state.status = 'succeeded';
//       //   state.user.tags = action.payload.tags;
//       //   console.log("user tags", state.user.tags)
//       // })
//       .addCase(fetchTags.fulfilled, (state, action) => {
//         console.warn("Incoming payload for tags:", action.payload);
//         if (action.payload && action.payload.tags) {
//           state.status = 'succeeded';
//           state.user.tags = action.payload.tags;
//           console.log("Updated user tags:", state.user.tags);
//         } else {
//           console.error("No tags found in payload:", action.payload);
//         }
//       })
      
//       .addCase(fetchTags.rejected, (state, action) => {
//         state.status = 'failed';
//         state.error = action.payload;
//       });
//   },
// });


// export default userSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { fetchPrimaryUser, getCookie } from './setting';
import { fetchBlockedList } from './blockList';
import { fetchLabels } from './labels';
import { fetchTags } from './tags';
export { editPrimaryUser, deleteSessions, getCookie, fetchPrimaryUser, updatePassword } from './setting';
export { delFromBlocklist, fetchBlockedList, uploadManualBlocklist, uploadSheet } from './blockList';
export { createLabel, deleteLabel, updateLabel, fetchLabels } from './labels';
export { changeRoles, deleteRole, getTeam, sendInvite } from './team';
export { createTag, deleteTag, updateTag, fetchTags } from './tags';
// export { fetchCampaignLeads } from './leads'

const initialState = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    timeZone: '',
    team: [],
    blockedEntries: [],
    tags: [],
    labels: [],
    cgptK: {}
  },
  status: 'idle',
  sid: getCookie('pu_sid') || null,
  error: null,
};

// Create the slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder
      // Cases for fetching primary user
      .addCase(fetchPrimaryUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPrimaryUser.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
        state.status = 'succeeded';
      })
      .addCase(fetchPrimaryUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Cases for fetching blocked list
      .addCase(fetchBlockedList.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchBlockedList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user.blockedEntries = action.payload.blockedEntries;
      })
      .addCase(fetchBlockedList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Cases for fetching labels
      .addCase(fetchLabels.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchLabels.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user.labelNames = [
          'Interested',
          'Not Interested',
          'Out of Office',
          'Wrong Person',
          'Not Contacted Yet',
          ...action.payload.labels?.map(i => i.name)];
        state.user.labels = [...action.payload.labels];
      })
      .addCase(fetchLabels.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Cases for fetching tags
      .addCase(fetchTags.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user.tags = action.payload.tags;
      })
      .addCase(fetchTags.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});


export default userSlice.reducer;