import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect, useRef, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import "@fontsource/poppins";
import "./App.css";
import store from "./store";
import Loader from "./Components/Buttons/loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFoundPage from "./Components/errorIllustrations/not-found";
import PasswordReset from "./Components/Forgot-Password/passwordResetPopup";
import EmailSentPopup from "./Components/Forgot-Password/emailSentPopup";
import ActivateAccount from "./Pages/SignIn/activateAccount";
import { Register } from "./Components/Forgot-Password/register";
import { fetchBlockedList, fetchLabels, fetchPrimaryUser, getTeam, fetchTags } from "./store/userSlice";
import DomainPurchase from "./Pages/Dashboard-pages/Accounts/domainPurchase"
import TestConfiguration from "./Pages/Dashboard-pages/DeliveryChecker/basicConf";
const DashboardPage = lazy(() => import("./Pages/Dashboard/Dashboard"));
const CreateNewPassword = lazy(() =>
  import("./Components/Forgot-Password/setNewPassword")
);
const ProtectedRoute = lazy(() => import("./Components/protected/Protected"));
const CreateCampaign = lazy(() => import("./Pages/campaign/createCampaign"));
const BulkDomain = lazy(() =>
  import("./Pages/Dashboard-pages/Accounts/bulkDomain")
);
const DomainCheckout = lazy(() =>
  import("./Pages/Dashboard-pages/Accounts/DomainCheckout")
);
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      dispatch(fetchPrimaryUser());
      dispatch(fetchBlockedList());
      dispatch(fetchTags());
      dispatch(fetchLabels());
    }
  }, [])
  // window.addEventListener('load', adjustPadding);
  // window.addEventListener('resize', adjustPadding);
  // function adjustPadding() {
  //   const header = document.querySelector('.header-main');
  //   const sibling = header.nextElementSibling;
  //   if (header && sibling) {
  //     const headerHeight = header.offsetHeight;
  //     sibling.style.paddingTop = `${headerHeight}px`;
  //   }
  // }
  return (
    <div>
      {/* Single ToastContainer at the root of your app */}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastClassName="text-xs "
      />
      {/* <Provider store={store}> */}
      <Router>
        <Suspense fallback={<Loader />}>
          {" "}
          {/* Display loader while components are lazy-loaded */}
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard/campaigns" replace />} />
            <Route path="/login" element={<Register />} />
            <Route path="/signup" element={<Register />} />
            <Route path="/forget-pswd" element={<PasswordReset />} />
            <Route path="/activate-account/:email" element={<ActivateAccount />} />
            <Route path="/change-password" element={<CreateNewPassword />} />
            <Route path="/email-sent/:email" element={<EmailSentPopup />} />
            <Route path="/test-configuration" element={<TestConfiguration />} /> 
            <Route path="/domain-p" element={<DomainPurchase />} />
            <Route
              path="/resetPassword/:resetToken"
              element={<CreateNewPassword />}
            />
            <Route
              path="/dashboard/*"
              element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/campaigns/create"
              element={
                <ProtectedRoute>
                  <CreateCampaign />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </Router>
      {/* </Provider> */}
    </div>
  );
}
{/* // export default App; */}
export default () => {
  return (
    <Provider store={store}>
      {/* LocalizationProvider is wrapped around your app */}
      <LocalizationProvider dateAdapter={AdapterDayjs}> 
        <App />
      </LocalizationProvider>
    </Provider>
  );
};