import React from "react";
import PropTypes from "prop-types";
import { closebtn } from "../../assets";
const RoundedInput = ({
  icon: Icon = null,
  iconDir = "left",
  value,
  onChange,
  name = "",
  type = "text",
  placeholder = "Search",
  bgColor = "bg-gray-bg",
  inputClasses = "border focus-within:outline-none border-light-gray2 min-h-[40px]",
  endlabel,
  min = 1,
  onClear = null,
  label = "",
  readOnly = false,
  onIconClick = () => { },
  onKeyDown=()=>{}
}) => {
  return (
    <div className="relative ">
      {Icon && (
        <div
          onClick={onIconClick}
          className={`p-[6px] rounded-full ${bgColor} absolute ${iconDir ? " left-[5px]" : 'right-[5px]'
            } top-0 bottom-0 m-auto rounded-full  h-fit    text-[#D1D5DB]`}
        >
          <Icon className="h-4 w-4" />
        </div>
      )}
      {label && value && (
        <label className="absolute left-4 px-1 text-[10px] text-gray-1 bg-white transition-transform transform -top-2 peer-focus:text-xs">
          {placeholder}
        </label>
      )}
      <input
        name={name}
        type={type}
        min={min}
        readOnly={readOnly}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        className={`w-full py-2 px-2 text-xs  font-normal placeholder:font-normal ${Icon && iconDir === "right" ? "pr-12 " : "pr-4"
          } ${Icon && iconDir === "left" ? "pl-10 " : "pl-4"} ${onClear ? "pr-12" : "pr-4"
          } pr-4 rounded-full ${inputClasses}`}
        value={value}
        onChange={onChange}
      />
      {onClear && value && (
        <div
          onClick={onClear}
          className={`rounded-full ${bgColor} cursor-pointer absolute right-2 top-1/2 transform -translate-y-1/2 `}
        >
          <img src={closebtn} alt="" className="w-8 h-8" />
        </div>
      )}
      {endlabel && (
        <span className="ml-2 text-xs bg-[#F3F4F6] absolute right-2 top-0 bottom-0 m-auto rounded-full font-medium h-fit  px-4 py-2 text-gray-text">
          {endlabel}
        </span>
      )}
    </div>
  );
};
RoundedInput.propTypes = {
  icon: PropTypes.elementType,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  bgColor: PropTypes.string,
  inputClasses: PropTypes.string,
};
export default RoundedInput;